import toast from "react-hot-toast";
import config from "./config";
import Tag from "./Tag";
import axios from "axios";

const { useState, useEffect } = require("react");

const AddVideo = (props) => {
  const [name, setName] = useState("");
  const [oneDriveURL, setOneDriveURL] = useState("");
  const [fileSize, setFileSize] = useState("");
  const [format, setFormat] = useState("");
  const [duration, setDuration] = useState("");
  const [tags, setTags] = useState([]);
  const [thumbnailFile, setThumbnailFile] = useState("");
  const [thumbnailLink, setThumbnailLink] = useState("");
  const [published, setPublished] = useState(false);

  const [tag, setTag] = useState("");

  const handleTagKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();

      const value = tag.trim().toLowerCase();
      if (!value) {
        setTag("");
        return;
      }

      setTags([value, ...tags]);
      setTag("");
    }
  };

  const removeTag = (index) => {
    let list = [...tags];
    list.splice(index, 1);
    setTags(list);
  };

  const fetchThumbnail = async (e) => {
    e.preventDefault();

    if (oneDriveURL === "") {
      return toast.error("Please provide OneDrive URL");
    }

    const driveURL = oneDriveURL.replace("?download=1", "");

    try {
      const formData = new FormData();
      formData.append("url", driveURL);

      const res = await axios.post(
        config.api_endpoint + "fetch-thumbnail",
        formData,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log({ res });
      setThumbnailLink(res.data.images[0]);
    } catch (error) {
      console.error(error.message);
      toast.error(error.response.data.error);
    }
  };

  const submit = async (e) => {
    e.preventDefault();

    if (oneDriveURL === "") {
      toast.error("Please provide OneDrive URL");
      return;
    }

    try {
      const formData = new FormData();

      formData.append("name", name);
      formData.append("oneDriveURL", oneDriveURL);
      formData.append("tags", tags);
      formData.append("fileSize", fileSize);
      formData.append("format", format);
      formData.append("duration", duration);
      formData.append("thumbnailFile", thumbnailFile);
      formData.append("thumbnailLink", thumbnailLink);
      formData.append("published", published);

      const res = await axios.post(config.api_endpoint + "video", formData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
        },
      });

      toast.success(res.data.message);
      props.setView(config.views.HOME);
    } catch (error) {
      console.error(error.message);
      toast.error(error.response.data.error);
    }
  };

  return (
    <div>
      <nav className="crumbs">
        <ol>
          <li
            onClick={() => props.setView(config.views.HOME)}
            className="crumb link"
          >
            Home
          </li>
          <li>
            Add Video{" "}
            <span
              className="link"
              onClick={() => props.setView(config.views.ADD_BULK_VIDEO)}
            >
              [Bulk Mode]
            </span>
          </li>
        </ol>
      </nav>
      <form onSubmit={submit}>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Name"
        />
        <input
          type="text"
          value={oneDriveURL}
          onChange={(e) => setOneDriveURL(e.target.value)}
          placeholder="OneDrive URL"
        />
        <button style={{ width: "fit-content" }} onClick={fetchThumbnail}>
          Fetch Thumbnail
        </button>
        <input
          type="text"
          value={fileSize}
          onChange={(e) => setFileSize(e.target.value)}
          placeholder="Filesize"
        />
        <input
          type="text"
          value={duration}
          onChange={(e) => setDuration(e.target.value)}
          placeholder="Duration"
        />
        <input
          type="text"
          value={format}
          onChange={(e) => setFormat(e.target.value)}
          placeholder="Format"
        />
        <input
          type="text"
          value={tag}
          onChange={(e) => setTag(e.target.value)}
          onKeyDown={handleTagKeyPress}
          placeholder="Add Tag (press enter)"
        />
        <div className="flex">
          {tags.map((tag, index) => (
            <Tag key={tag} text={tag} onClick={() => removeTag(index)} />
          ))}
        </div>
        <div className="flex flex-v-center">
          <label htmlFor="">Thumbnail File: </label>
          <input
            type="file"
            name="thumbnailFile"
            id="thumbnailFile"
            onChange={(e) => {
              console.log(e.target.files);
              setThumbnailFile(e.target.files[0]);
            }}
          />
        </div>
        <input
          type="text"
          name="thumbnailLink"
          id="thumbnailLink"
          value={thumbnailLink}
          placeholder="Thumbnail Link (either of link or file is required)"
          onChange={(e) => setThumbnailLink(e.target.value)}
        />
        <div className="flex flex-v-center">
          <label htmlFor="">Published</label>
          <input
            type="checkbox"
            name="published"
            id="published"
            checked={published}
            onChange={() => setPublished(!published)}
          />
        </div>
        <button>Submit</button>
      </form>
      {thumbnailLink.length > 0 && (
        <div className="pad">
          <h3>Thumbnail (Link)</h3>
          <div className="flex flex-v-center">
            <img src={thumbnailLink} alt="" style={{ maxHeight: "200px" }} />
          </div>
        </div>
      )}
    </div>
  );
};

export default AddVideo;
