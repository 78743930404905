const Tag = (props) => {
  return (
    <div
      className="tag"
      title="Click to remove"
      onClick={() => props.onClick()}
    >
      <div className="text">{props.text}</div>
    </div>
  );
};

export default Tag;
