import toast from "react-hot-toast";
import config from "./config";
import axios from "axios";
const { useState, useEffect } = require("react");

const AddBulkVideo = (props) => {
  const [urls, setURLs] = useState([""]);
  let fileReader;

  const addMore = (e) => {
    e.preventDefault();
    setURLs([...urls, [""]]);
  };

  const setURL = (index, value) => {
    let items = [...urls];
    items[index] = value;
    setURLs(items);
  };

  const handleFileChosen = (e) => {
    if (!e.target.files) {
      return;
    }

    fileReader = new FileReader();
    fileReader.onloadend = handleFileRead;
    fileReader.readAsText(e.target.files[0]);
  };

  const handleFileRead = (e) => {
    const content = fileReader.result;
    const lines = content.split("\n").map((x) => x.trim());
    setURLs(lines);
  };

  const submit = async (e) => {
    e.preventDefault();

    try {
      const res = await axios.post(
        config.api_endpoint + "bulk-video",
        {
          urls,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );

      toast.success(res.data.message);
      props.setView(config.views.HOME);
    } catch (error) {
      console.error(error.message);
      toast.error(error.response.data.error);
    }
  };

  return (
    <div>
      <nav className="crumbs">
        <ol>
          <li
            onClick={() => props.setView(config.views.HOME)}
            className="crumb link"
          >
            Home
          </li>
          <li>Add Bulk Videos</li>
        </ol>
      </nav>
      <form onSubmit={submit}>
        <div className="row">
          <span>Upload URLs TXT:</span>
          <input
            type="file"
            name="urls"
            style={{ flex: 1 }}
            onChange={handleFileChosen}
          />
        </div>
        {urls.map((url, index) => (
          <div className="row" key={index}>
            <input
              type="text"
              value={url}
              onChange={(e) => setURL(index, e.target.value)}
              placeholder="Name"
              style={{ flex: 1 }}
            />
          </div>
        ))}
        <button onClick={addMore}>Add More Video</button>
        <button>Submit</button>
      </form>
    </div>
  );
};

export default AddBulkVideo;
