const VIEWS = {
  LOGIN: 1,
  REGISTER: 2,
  HOME: 3,
  ADD_VIDEO: 5,
  EDIT_VIDEO: 7,
  VIDEO_LISTING: 9,
  ADD_BULK_VIDEO: 10,
};

const LIMITS = {
  DEFAULT_VIDEO_LISTING: 10,
};

const SS_QUEUE_VALUES = {
  0: "Pending",
  1: "Processing",
};

const config = {
  app_name: "FappZone",
  api_endpoint: process.env.REACT_APP_API_ENDPOINT,
  views: VIEWS,
  limits: LIMITS,
  SS_QUEUE_VALUES,
};

export default config;
