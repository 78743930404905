import { useState } from "react";
import config from "./config";
import toast from "react-hot-toast";

const Register = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const register = async (e) => {
    e.preventDefault();

    fetch(config.api_endpoint + "register", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    })
      .then((res) => res.json())
      .then((res) => {
        toast.success(res.message);
        props.setView(config.views.LOGIN);
      })
      .catch((error) => {
        console.error(error);
        toast.error(error.message.error);
      });
  };

  return (
    <div>
      <form className="register-form" onSubmit={register}>
        <input
          type="text"
          name="email"
          value={email}
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          name="password"
          value={password}
          placeholder="Password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <input
          type="password"
          name="confirmPassword"
          value={confirmPassword}
          placeholder="Confirm Password"
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <button>Submit</button>
      </form>
      <p
        className="click"
        style={{ textAlign: "center" }}
        onClick={() => props.setView(config.views.LOGIN)}
      >
        Login?
      </p>
    </div>
  );
};

export default Register;
