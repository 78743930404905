import VideoListing from "./VideoListing";
import config from "./config";

const Home = (props) => {
  return (
    <section>
      <div className="row pad">
        <button onClick={() => props.setView(config.views.ADD_VIDEO)}>
          Add Video
        </button>
      </div>
      <div className="pad">
        <div className="row">
          <h3>Videos</h3>
          <span
            className="link"
            onClick={() => props.setView(config.views.VIDEO_LISTING)}
          >
            [All]
          </span>
        </div>
        <VideoListing {...props} />
      </div>
    </section>
  );
};

export default Home;
