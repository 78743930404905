import { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import Auth from "./Auth";
import Dashboard from "./Dashboard";
import axios from "axios";
import config from "./config";

const initialToken = localStorage.getItem("token");

function App() {
  const [token, setToken] = useState(initialToken);

  useEffect(() => {
    if (token) {
      localStorage.setItem("token", token);
      authCheck();
    } else {
      localStorage.removeItem("token");
    }
  }, [token]);

  const authCheck = async () => {
    try {
      await axios.get(config.api_endpoint + "auth-check", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
    } catch (error) {
      setToken(null);
    }
  };

  return (
    <div>
      <Toaster />
      <header>
        <h1>{config.app_name}</h1>
        {token && (
          <div
            className="logout"
            onClick={() => setToken(null)}
            style={{ cursor: "pointer" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M18 2H5a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h13a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zM7 12h11M10 16l4-4-4-4" />
            </svg>
          </div>
        )}
      </header>
      <main>{token ? <Dashboard /> : <Auth setToken={setToken} />}</main>
    </div>
  );
}

export default App;
