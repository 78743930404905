import { useState } from "react";
import config from "./config";
import toast from "react-hot-toast";
import axios from "axios";

const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const login = async (e) => {
    e.preventDefault();

    try {
      const res = await axios.post(config.api_endpoint + "login", {
        email,
        password,
      });
      props.setToken(res.data.token);
    } catch (error) {
      console.error(error.message);
      toast.error(error.response.data.error);
    }
  };

  return (
    <div>
      <form className="login-form" onSubmit={login}>
        <input
          type="text"
          name="email"
          value={email}
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          name="password"
          value={password}
          placeholder="Password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <button>Submit</button>
      </form>
      <p
        className="click"
        style={{ textAlign: "center" }}
        onClick={() => props.setView(config.views.REGISTER)}
      >
        Register?
      </p>
    </div>
  );
};

export default Login;
